import React, {Component} from 'react';
import { Button, Col, Row, Container } from 'react-bootstrap';
import './App.css';

class App extends Component {

  render() {
    return (
      <div className="App">
        <Container className="page-header">
          <Row>
            <Col xs="12" className="page-header-login-btn-container pb-0">
              <Button variant="primary" href={process.env.REACT_APP_MYTRACKS_APP_URL}>Sign in</Button>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default App;
